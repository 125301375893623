.burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

.link {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.links {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.image {
    height: 32px;
}

.text {
    text-align: center;
    font-family: Freckle Face, cursive;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.28px;
}

.modal {
    width: 100px; /* Ширина модального окна */
    height: 180px; /* Высота модального окна */
    background-color: white; /* Цвет фона модального окна */
    border: 2px solid #333; /* Граница модального окна */
    padding: 20px; /* Отступ внутри модального окна */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Тень модального окна */
}