.footer {
    display: flex;
    width: 100vw;
    height: 300px;
    padding-top: 30px;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;

    background: url('../../assets/back.png') no-repeat;
    background-position: center;
    background-size: cover;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.mail {
    /*color: #09203C;*/
    color: #EA6166;
    text-align: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Anek Bangla, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.28px;
}

.logo {
    width: 36px;
    height: 36px;
    padding: 16px;
    background: url("../../assets/bird.png") no-repeat;
    background-size: cover;
}

.media {
    display: flex;
    padding: 0 5vw;
    align-items: center;
    gap: 12px;
}

.copyright {
    color: #FBF9F0;
    font-family: Geologica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    letter-spacing: -0.32px;
}