.news {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2vw;
}

.error {
    height: 900px;
    text-align: center;
    font-family: Freckle Face, cursive;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.28px;
}