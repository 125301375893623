.menu {
    position: sticky;
    top: 0;
    margin-left: 3vw;
    margin-right: 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    z-index: 100;

    border-bottom: 2px solid #B52323;
}

.media {
    display: flex;
    padding: 0 5vw;
    align-items: center;
    gap: 12px;
}

.donate {
    display: flex;
    padding: 0 5vw;
    align-items: center;
    gap: 12px;
}

.link {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.text {
    text-align: center;
    font-family: Freckle Face, cursive;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.28px;
}