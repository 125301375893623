.header {
    display: flex;
    height: 300px;
    padding-top: 30px;
    padding-bottom: 10px;
    flex-direction: column;
    justify-content: center;
    /*align-items: flex-start;*/
    align-items: center;
    background: url('../../assets/back.png') no-repeat center;
    background-size: cover;
}

@media (max-width: 800px) {
    .boy {
        display: none;
    }
}
@media (min-width: 800px) {
    .boy {
        width: 288px;
        height: 270px;
        margin-top: auto;
        background: url('../../assets/boy.png') no-repeat;
        background-size: cover;
    }
}
.title {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.up {
    padding-left: 5vw;
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 128px;
    height: 32px;
    background: url("../../assets/logo.png") no-repeat;
    background-size: contain;
}

.stub {
    width: 128px;
}

.title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: 100%;*/
}

.title_img {
    width: 100%;
    /*height: 100%;*/
    /*background: url("../../assets/title.png") no-repeat;*/
    /*background-size: contain;*/
}

.text {
    width: 300px;
    color: #EA6166;
    text-align: center;
    /*leading-trim: both;*/
    /*text-edge: cap;*/
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Freckle Face", system-ui;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.64px;
    -webkit-text-stroke: 1px rgba(30, 33, 55, 0.6);
    text-stroke: 1px rgba(30, 33, 55, 0.6);
}

@media (max-width: 800px) {
    .boy {
        display: none;
    }
}

@media (max-width: 800px) {
    .text {
        width: 300px;
        color: #EA6166;
        text-align: center;
        /*leading-trim: both;*/
        /*text-edge: cap;*/
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Freckle Face", system-ui;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.64px;
        -webkit-text-stroke: 1px rgba(30, 33, 55, 0.6);
        text-stroke: 1px rgba(30, 33, 55, 0.6);
    }
}
@media (min-width: 800px) {
    .text {
        width: 300px;
        color: #EA6166;
        text-align: center;
        /*leading-trim: both;*/
        /*text-edge: cap;*/
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Freckle Face", system-ui;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.64px;
        -webkit-text-stroke: 1px rgba(30, 33, 55, 0.6);
        text-stroke: 1px rgba(30, 33, 55, 0.6);
    }
}